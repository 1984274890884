import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import './styles/index.scss';
import { Container, Row, Col, Stack } from "react-bootstrap";


const news = ({ data }) => {
  console.log(data)
  const imgRepeat = data.allDatoCmsNewPage.nodes[0].bannerPage.url;


  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common news">
        <Row>
          <h1>{data.allDatoCmsNewPage.nodes[0].buttonBannerPage[0].destination.title}</h1>
          <Stack className="card-long">
            <div style={{ backgroundImage: `url(${imgRepeat})`, width: '100%', height: '100%' }}></div>
            <div className='back-image'>
              <h4>Authentic Digital Trading Cards</h4>
              <p>Creation / Minting / Marketing Custom Curated Collectibles</p>
            </div>
          </Stack>
          <Col xs={12} md={6} className='links-segment'>
            <h5>As Seen On:</h5>
            <div className='links'>
              {data.allDatoCmsNewPage.nodes[0].listExternalPageAsSeenOn.map((listExternalPage, index) =>
                <div key={index}>
                  <a target="_blank" rel="noreferrer" href={listExternalPage.url}>{listExternalPage.label}</a>
                </div>
              )
              }
            </div>
          </Col>
          <Col xs={12} md={6}>
            <img alt='logos-news' src={data.allDatoCmsNewPage.nodes[0].imageAsSeenOn.url} />
          </Col>
          <hr></hr>
          {data.allDatoCmsNewPage.nodes[0].pressList.map((pressList, index) =>
            <div key={index} className='per-news'>
              <h4>{pressList.pressName}</h4>
              <a target="_blank" rel="noreferrer" href={pressList.title[0].url}>{pressList.title[0].label}</a>
              <p>{pressList.description}</p>
              <a target="_blank" rel="noreferrer" href={pressList.url[0].url}>{pressList.url[0].label}</a>
            </div>
          )
          }
        </Row>
      </Container>
    </div>
  )
}

export default news


export const Head = ({ data }) => (
  <Seo
    title="News"
    description={data.allDatoCmsNewPage.nodes[0].seo.description}
    image={data.allDatoCmsNewPage.nodes[0].seo.image.url}
  />
)

export const query = graphql`
query NewsQuery {
  allDatoCmsNewPage {
    nodes {
      seo {
        title
        description
        image {
          url
        }
      }
      infoPage {
        title
        slug
      }
      bannerPage {
        url
      }
      buttonBannerPage {
        label
        destination {
          title
          slug
        }
      }
      imageAsSeenOn {
        url
      }
      listExternalPageAsSeenOn {
        label
        url
      }
      pressList {
        pressName
        title {
          url
          label
        }
        description
        url {
          label
          url
        }
      }
    }
  }
}
`